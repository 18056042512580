import React, { useEffect } from 'react';

const GoogleTagManager: React.FC = () => {
  useEffect(() => {
    // Create script elements
    const scriptAsync = document.createElement('script');
    scriptAsync.async = true;
    scriptAsync.src = "https://www.googletagmanager.com/gtag/js?id=AW-16676678730";

    const scriptInline = document.createElement('script');
    scriptInline.text = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'AW-16676678730');
    `;

    // Append the scripts to the document head
    document.head.appendChild(scriptAsync);
    document.head.appendChild(scriptInline);

    // Cleanup function
    return () => {
      document.head.removeChild(scriptAsync);
      document.head.removeChild(scriptInline);
    };
  }, []); // Empty dependency array ensures this runs once on mount

  return null; // This component doesn't render anything
};

export default GoogleTagManager;