import React, { useState, useEffect, ReactNode, useCallback } from 'react';
import { ChartBar, Cog, PieChart, BarChart, FileSpreadsheet, TrendingUp, Clock, ShoppingBag, Coffee, Tv, Apple, Mail, MapPin, Moon, Sun, Zap } from 'lucide-react';
import { Users, Award, Wind, Globe, Brain, Rocket, Wrench, Code, Database, GitBranch } from 'lucide-react';
import { useInView } from 'react-intersection-observer';
import { createClient } from '@supabase/supabase-js';
import HeroSection from './HeroSection';
import GoogleTagManager from './GoogleTagManager'; 


const supabase = createClient('https://qpzpynfspiuhozwzleiq.supabase.co', 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InFwenB5bmZzcGl1aG96d3psZWlxIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MjM4NDIwNTAsImV4cCI6MjAzOTQxODA1MH0.oOCkVamMcwolkIX8hx6IRUPK38-wLC0U00aYmRmjaT4');

declare global {
  interface Window {
    ym: (counterId: number, action: string, parameters?: any) => void;
  }
}

const YandexMetrika: React.FC = () => {
  useEffect(() => {
    // Create a script element
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.text = `
      (function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
      m[i].l=1*new Date();
      for (var j = 0; j < document.scripts.length; j++) {if (document.scripts[j].src === r) { return; }}
      k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})
      (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");
      ym(98042431, "init", {
        clickmap:true,
        trackLinks:true,
        accurateTrackBounce:true,
        webvisor:true
      });
    `;

    // Append the script to the document body
    document.body.appendChild(script);

    // Clean up function
    return () => {
      document.body.removeChild(script);
    };
  }, []); // Empty dependency array ensures this runs once on mount

  return null; // This component doesn't render anything
};

const LandingPage: React.FC = () => {
  const [darkMode, setDarkMode] = useState<boolean>(false);
  
  const [email, setEmail] = useState<string>('');
  const [subscribeStatus, setSubscribeStatus] = useState<string>('');

  
  useEffect(() => {
    const script = document.createElement('script');
    script.src = "https://assets.calendly.com/assets/external/widget.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);



  const handleEmailSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setSubscribeStatus('Subscribing...');

    try {
      const { data, error } = await supabase
        .from('subscribers')
        .insert([{ email: email }]);

      if (error) throw error;

      console.log('Subscription successful:', data);
      setEmail('');
      setSubscribeStatus('Thank you for subscribing!');
    } catch (error) {
      console.error('Error subscribing:', error);
      setSubscribeStatus('An error occurred. Please try again.');
    }

    setTimeout(() => setSubscribeStatus(''), 3000);
  };

  const CalendlyWidget: React.FC = React.memo(() => {
  const [isCalendlyReady, setIsCalendlyReady] = useState(false);

  useEffect(() => {
    if (!(window as any).Calendly) {
      const script = document.createElement('script');
      script.src = "https://assets.calendly.com/assets/external/widget.js";
      script.async = true;
      script.onload = () => setIsCalendlyReady(true);
      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      };
    } else {
      setIsCalendlyReady(true);
    }
  }, []);

  useEffect(() => {
    if (isCalendlyReady) {
      (window as any).Calendly.initInlineWidget({
        url: 'https://calendly.com/ilyagolubev3581/30min',
        parentElement: document.getElementById('calendly-inline-widget'),
        prefill: {},
        utm: {}
      });
    }
  }, [isCalendlyReady]);

  return <div id="calendly-inline-widget" style={{ minWidth: '320px', height: '700px' }} />;
});

const EmailSubscriptionForm: React.FC = () => {
  const [email, setEmail] = useState<string>('');
  const [subscribeStatus, setSubscribeStatus] = useState<string>('');

  const handleEmailSubmit = useCallback(async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setSubscribeStatus('Subscribing...');

    try {
      const { data, error } = await supabase
        .from('subscribers')
        .insert([{ email: email }]);

      if (error) throw error;

      console.log('Subscription successful:', data);
      setEmail('');
      setSubscribeStatus('Thank you for subscribing!');
    } catch (error) {
      console.error('Error subscribing:', error);
      setSubscribeStatus('An error occurred. Please try again.');
    }

    setTimeout(() => setSubscribeStatus(''), 3000);
  }, [email]);

  return (
    <form onSubmit={handleEmailSubmit} className="flex flex-col">
      <div className="flex mb-2">
        <input
          type="email"
          placeholder="Enter your email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="flex-grow px-3 py-2 text-gray-700 rounded-l-md focus:outline-none"
          required
        />
        <button
          type="submit"
          className="bg-blue-600 text-white px-4 py-2 rounded-r-md hover:bg-blue-700 transition duration-300"
        >
          Subscribe
        </button>
      </div>
      {subscribeStatus && (
        <p className={`text-sm ${subscribeStatus.includes('error') ? 'text-red-400' : 'text-green-400'}`}>
          {subscribeStatus}
        </p>
      )}
    </form>
  );
};


  useEffect(() => {
    if (darkMode) {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
  }, [darkMode]);

  useEffect(() => {
    // Add global styles
    const style = document.createElement('style');
    style.textContent = `
      @keyframes spin-slow {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
      }
      .animate-spin-slow {
        animation: spin-slow 10s linear infinite;
      }
      @keyframes float {
        0%, 100% { transform: translateY(0px); }
        50% { transform: translateY(-20px); }
      }
      .animate-float {
        animation: float 5s ease-in-out infinite;
      }
    `;
    document.head.appendChild(style);

    // Cleanup function to remove the style when the component unmounts
    return () => {
      document.head.removeChild(style);
    };
  }, []); // Empty dependency array means this effect runs once on mount and cleanup on unmount

  const scrollTo = (id: string): void => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };


  interface AnimatedSectionProps {
    children: ReactNode;
    id: string;
  }

  const AnimatedSection: React.FC<AnimatedSectionProps> = ({ children, id }) => {
    const { ref, inView } = useInView({
      threshold: 0.2,
      triggerOnce: true,
    });

    return (
      <div
        ref={ref}
        id={id}
        className={`transition-all duration-1000 ease-out ${
          inView ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'
        }`}
      >
        {children}
      </div>
    );
  };


  return (
    <div className={`min-h-screen font-sans ${darkMode ? 'dark' : ''}`}>
      {/* Header */}
      <header className="bg-white dark:bg-gray-800 shadow-sm fixed w-full z-10 transition-all duration-300 ease-in-out" style={{
        background: darkMode ? 'rgba(31, 41, 55, 0.8)' : 'rgba(255, 255, 255, 0.8)',
        backdropFilter: 'blur(5px)'
      }}>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4 flex justify-between items-center">
          <div className="flex items-center">
            <Wind className="h-8 w-8 text-blue-600 dark:text-blue-400 mr-2 animate-pulse" />
            <span className="text-xl font-bold text-gray-900 dark:text-white">Hermes BI</span>
          </div>
          <nav className="flex items-center">
            <ul className="flex space-x-6 mr-6">
              {['Services', 'Industries', 'About', 'Contact'].map((item, index) => (
                <li key={index}>
                  <a
                    href={`#${item.toLowerCase()}`}
                    onClick={(e) => {
                      e.preventDefault();
                      scrollTo(item.toLowerCase());
                    }}
                    className="text-gray-600 dark:text-gray-300 hover:text-blue-600 dark:hover:text-blue-400 transition duration-300"
                  >
                    {item}
                  </a>
                </li>
              ))}
            </ul>
            <button
              onClick={() => setDarkMode(!darkMode)}
              className="p-2 rounded-full bg-gray-200 dark:bg-gray-700 text-gray-800 dark:text-gray-200 transition duration-300"
            >
              {darkMode ? <Sun className="h-5 w-5" /> : <Moon className="h-5 w-5" />}
            </button>
          </nav>
        </div>
      </header>

      {/* Hero Section */}
      <AnimatedSection id="hero">
        <HeroSection onScrollTo={scrollTo} />
      </AnimatedSection>


      {/* Services Section */}
      <AnimatedSection id="services">
        <section className="py-16 bg-white dark:bg-gray-900">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <h2 className="text-3xl font-bold text-center mb-12 text-gray-900 dark:text-white">Our Adaptive Approach</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
              {[
                { 
                  icon: Wrench, 
                  title: "Seamless Integration", 
                  description: "We adapt to the tools you already use, ensuring a natural fit with your existing processes.",
                  features: [
                    "Compatible with popular tools like SAP, Oracle, Microsoft Dynamics, and QuickBooks",
                    "Custom integrations for industry-specific software",
                    "Minimal disruption to your current workflows"
                  ],
                  example: "Retail client using SAP developed custom scripts that automated their monthly reporting process, reducing manual work by 70%."
                },
                { 
                  icon: GitBranch, 
                  title: "Scalable Solutions", 
                  description: "From simple Excel macros to complex AI-driven systems, we provide the right solution for your needs.",
                  features: [
                    "Excel templates and macros for quick wins",
                    "Power BI and Power Automate for mid-level automation",
                    "Custom SQL databases and AI solutions for complex requirements"
                  ],
                  example: "Growing FMCG company started with a simpl Excel macros, then gradually introduced Power BI dashboards as their needs evolved, saving them 20 hours per week in reporting time."
                },
                { 
                  icon: Rocket, 
                  title: "Future-Proof Strategy", 
                  description: "We plan for your future growth, ensuring solutions that evolve with your business.",
                  features: [
                    "Long-term roadmap development",
                    "Regular solution assessments and upgrades",
                    "Guidance on when to transition to enterprise-level solutions"
                  ],
                  example: "Tech startup implemented a modular financial modeling system that easily scaled as they grew from Series A to IPO, saving them from multiple system overhauls."
                },
                { 
                  icon: Users, 
                  title: "Industry-Specific Expertise", 
                  description: "We understand your industry's unique metrics and management needs.",
                  features: [
                    "Deep knowledge of retail, FMCG, tech, and manufacturing sectors",
                    "Custom KPI dashboards tailored to your industry",
                    "Benchmarking against industry standards"
                  ],
                  example: "Hospitality chain developed a real-time occupancy and revenue optimization system, increasing their RevPAR by 15%."
                }
              ].map((service, index) => (
                <div key={index} className="bg-gray-100 dark:bg-gray-800 p-6 rounded-lg shadow-md">
                  <div className="flex items-center mb-4">
                    <div className="bg-blue-100 dark:bg-blue-900 p-2 rounded-full mr-4">
                      <service.icon className="h-6 w-6 text-blue-600 dark:text-blue-400" />
                    </div>
                    <h3 className="text-xl font-semibold text-gray-900 dark:text-white">{service.title}</h3>
                  </div>
                  <p className="text-gray-600 dark:text-gray-300 mb-4">{service.description}</p>
                  <ul className="list-disc list-inside text-gray-600 dark:text-gray-300 mb-4">
                    {service.features.map((feature, fIndex) => (
                      <li key={fIndex} className="mb-2">{feature}</li>
                    ))}
                  </ul>
                  <p className="text-sm italic text-gray-500 dark:text-gray-400">
                    <strong>Example:</strong> {service.example}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </section>
      </AnimatedSection>

      {/* New Section: Our Tools */}
      <AnimatedSection id="tools">
        <section className="py-16 bg-gray-100 dark:bg-gray-800">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <h2 className="text-3xl font-bold text-center mb-12 text-gray-900 dark:text-white">Tools We Leverage</h2>
            <p className="text-center text-gray-600 dark:text-gray-300 mb-8">
              We're proficient in a wide range of tools, allowing us to adapt to your existing systems and recommend the best solutions for your needs.
            </p>
            <div className="grid grid-cols-2 md:grid-cols-4 gap-8">
              {[
                { name: "Excel & VBA", icon: FileSpreadsheet },
                { name: "Power BI", icon: ChartBar },
                { name: "Power Automate", icon: Cog },
                { name: "SQL Databases", icon: Database },
                { name: "Python", icon: Code },
                { name: "SAP", icon: BarChart },
                { name: "Oracle", icon: PieChart },
                { name: "Tableau", icon: TrendingUp },
              ].map((tool, index) => (
                <div key={index} className="flex flex-col items-center bg-white dark:bg-gray-900 p-4 rounded-lg shadow-md">
                  <tool.icon className="h-12 w-12 text-blue-600 dark:text-blue-400 mb-2" />
                  <span className="text-gray-800 dark:text-gray-200 text-center">{tool.name}</span>
                </div>
              ))}
            </div>
          </div>
        </section>
      </AnimatedSection>

      

      {/* Industries Section */}
      <AnimatedSection id="industries">
        <section className="py-16 bg-gray-100 dark:bg-gray-800">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <h2 className="text-3xl font-bold text-center mb-12 text-gray-900 dark:text-white">Industry Expertise</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
              {[
                { 
                  icon: ShoppingBag, 
                  title: "Retail", 
                  description: "Optimize inventory management and sales forecasting",
                  example: "Automated demand forecasting helped a fashion retailer reduce excess inventory by 30% and increase sell-through rates by 25%."
                },
                { 
                  icon: Coffee, 
                  title: "Hospitality", 
                  description: "Streamline cost control and revenue management",
                  example: "AI-driven pricing optimization increased a hotel chain's RevPAR by 18% during peak seasons."
                },
                { 
                  icon: Tv, 
                  title: "TMT", 
                  description: "Enhance project-based accounting and revenue recognition",
                  example: "Automated revenue recognition for a SaaS company reduced compliance risks and cut the monthly close process by 50%."
                },
                { 
                  icon: Apple, 
                  title: "FMCG", 
                  description: "Improve supply chain finance and trade promotion analysis",
                  example: "Trade promotion effectiveness analysis helped a beverage company reallocate $10M in marketing spend, boosting ROI by 35%."
                }
              ].map((industry, index) => (
                <div key={index} className="flex flex-col items-center text-center bg-white dark:bg-gray-900 p-6 rounded-lg shadow-md">
                  <div className="bg-blue-100 dark:bg-blue-900 p-3 rounded-full mb-4">
                    <industry.icon className="h-8 w-8 text-blue-600 dark:text-blue-400" />
                  </div>
                  <h3 className="text-xl font-semibold mb-2 text-gray-900 dark:text-white">{industry.title}</h3>
                  <p className="text-gray-600 dark:text-gray-300 mb-4">{industry.description}</p>
                  <p className="text-sm italic text-gray-500 dark:text-gray-400">
                    <strong>Example:</strong> {industry.example}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </section>
      </AnimatedSection>

     
      {/* Updated About Section */}
      <AnimatedSection id="about">
        <section className="py-16 bg-white dark:bg-gray-900">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <h2 className="text-3xl font-bold text-center mb-12 text-gray-900 dark:text-white">Why Choose Hermes BI</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
              <div>
                <p className="text-gray-600 dark:text-gray-300 mb-4">
                  At Hermes BI, we understand that every business is unique. Our approach is not about pushing pre-made solutions, but about deeply understanding your specific needs and challenges.
                </p>
                <p className="text-gray-600 dark:text-gray-300 mb-4">
                  We start by listening to your team and analyzing your current processes. This allows us to identify quick wins for immediate impact, while also planning for long-term scalability and growth.
                </p>
                <ul className="list-disc list-inside text-gray-600 dark:text-gray-300 mb-4">
                  <li>Customized solutions that adapt to your existing tools and processes</li>
                  <li>Scalable implementations that grow with your business</li>
                  <li>Deep industry expertise across retail, FMCG, tech, and manufacturing</li>
                  <li>Focus on employee adoption and change management</li>
                  <li>Ongoing support and strategy refinement</li>
                </ul>
              </div>
              <div className="bg-gray-100 dark:bg-gray-800 p-6 rounded-lg shadow-md">
                <h3 className="text-xl font-semibold mb-4 text-gray-900 dark:text-white">Our Commitment to You</h3>
                <div className="space-y-4">
                  {[
                    { icon: Users, commitment: 'Understanding Your Unique Needs', level: 95 },
                    { icon: Rocket, commitment: 'Future-Proofing Your Processes', level: 92 },
                    { icon: Brain, commitment: 'Continuous Learning & Innovation', level: 98 },
                    { icon: Award, commitment: 'Delivering Measurable Results', level: 90 },
                  ].map((item, index) => (
                    <div key={index} className="flex items-center">
                      <item.icon className="h-5 w-5 text-blue-600 dark:text-blue-400 mr-2" />
                      <div className="flex-grow">
                        <div className="flex justify-between mb-1">
                          <span className="text-sm font-medium text-gray-700 dark:text-gray-300">{item.commitment}</span>
                          <span className="text-sm font-medium text-gray-700 dark:text-gray-300">{item.level}%</span>
                        </div>
                        <div className="w-full bg-gray-200 dark:bg-gray-700 rounded-full h-2.5">
                          <div className="bg-blue-600 dark:bg-blue-400 h-2.5 rounded-full" style={{width: `${item.level}%`}}></div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </section>
      </AnimatedSection>

      <AnimatedSection id="contact">
        <section className="py-16 bg-gray-100 dark:bg-gray-800">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <h2 className="text-3xl font-bold text-center mb-12 text-gray-900 dark:text-white">Book a Call</h2>
            <div className="max-w-3xl mx-auto">
              <p className="text-center text-gray-600 dark:text-gray-300 mb-8">
                Schedule a 30-minute call with our team to discuss your needs and how we can help optimize your financial processes with our AI-powered automation solutions.
              </p>
              <CalendlyWidget />
            </div>
          </div>
        </section>
      </AnimatedSection>

      {/* Footer */}
      <footer className="bg-gray-800 dark:bg-gray-900 text-white py-12">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
            <div>
              <h3 className="text-lg font-semibold mb-4">Hermes BI</h3>
              <p className="text-gray-400">Swift financial intelligence for modern businesses.</p>
            </div>
            <div>
              <h3 className="text-lg font-semibold mb-4">Quick Links</h3>
              <ul className="space-y-2">
                {['Home', 'Services', 'Industries', 'About', 'Contact'].map((item, index) => (
                  <li key={index}>
                    <a
                      href={`#${item.toLowerCase()}`}
                      onClick={(e) => {
                        e.preventDefault();
                        scrollTo(item.toLowerCase());
                      }}
                      className="text-gray-400 hover:text-white transition duration-300"
                    >
                      {item}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
            <div>
              <h3 className="text-lg font-semibold mb-4">Contact Us</h3>
              <ul className="space-y-2">
                <li className="flex items-center text-gray-400">
                  <Mail className="h-5 w-5 mr-2" /> info@hermesbi.com
                </li>
            
                <li className="flex items-center text-gray-400">
                  <MapPin className="h-5 w-5 mr-2" /> Amsterdam, Netherlands
                </li>
              </ul>
            </div>
            <div>
              <h3 className="text-lg font-semibold mb-4">Stay Updated</h3>
              <p className="text-gray-400 mb-2">Subscribe to our newsletter for the latest updates and insights.</p>
              <EmailSubscriptionForm />
            </div>
          </div>
          <div className="mt-8 pt-8 border-t border-gray-700 text-center text-gray-400">
            <p>&copy; {new Date().getFullYear()} Hermes Business Intelligence. All rights reserved.</p>
          </div>
        </div>
      </footer>

     <YandexMetrika />
     <GoogleTagManager />
    </div>
  );
};

export default LandingPage;