import React, { useState, useEffect } from 'react';
import { Zap } from 'lucide-react';

interface HeroSectionProps {
  onScrollTo: (id: string) => void;
}

const HeroSection: React.FC<HeroSectionProps> = ({ onScrollTo }) => {
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);
  const [isCurtainOpen, setIsCurtainOpen] = useState(false);

  useEffect(() => {
    const video = document.getElementById('background-video') as HTMLVideoElement;
    if (video) {
      video.addEventListener('loadeddata', () => {
        setIsVideoLoaded(true);
        setTimeout(() => setIsCurtainOpen(true), 500); // Delay curtain opening for smoother transition
      });
    }

    return () => {
      if (video) {
        video.removeEventListener('loadeddata', () => {
          setIsVideoLoaded(true);
          setTimeout(() => setIsCurtainOpen(true), 500);
        });
      }
    };
  }, []);

  return (
    <div className="relative overflow-hidden bg-gradient-to-r from-blue-600 to-indigo-700 dark:from-blue-800 dark:to-indigo-900 text-white pt-24">
      {/* Background Image (shown while video is loading) */}
      <div 
        className="absolute inset-0 bg-cover bg-center transition-opacity duration-1000"
        style={{
          backgroundImage: 'url("/hero.jpg")',
          opacity: isVideoLoaded ? 0 : 1,
        }}
      />

      {/* Video Background */}
      <video
        id="background-video"
        className="absolute inset-0 w-full h-full object-cover"
        autoPlay
        loop
        muted
        playsInline
        style={{ opacity: isVideoLoaded ? 1 : 0 }}
      >
        <source src="/hermes-bi.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>

      {/* Gradient Overlay */}
      <div 
        className="absolute inset-0 bg-gradient-to-r from-blue-900/90 to-blue-900/40"
        style={{ mixBlendMode: 'multiply' }}
      />
      {/* Curtain Animation */}
      <div 
        className={`absolute inset-0 bg-blue-900 transition-transform duration-1000 ease-in-out ${
          isCurtainOpen ? 'translate-y-full' : ''
        }`}
      />

      {/* Content */}
      <div className="relative z-10 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-24">
        <div className="flex flex-col md:flex-row items-center justify-between">
          <div className="md:w-1/2 mb-8 md:mb-0">
            <h1 className="text-4xl md:text-5xl font-bold mb-4">
              Tailored Financial Automation for Your Business
            </h1>
            <p className="text-xl mb-8">
              We adapt to your existing tools and processes, providing seamless integration and scalable solutions for businesses of all sizes and complexities.
            </p>
            <button 
              onClick={() => onScrollTo('contact')}
              className="bg-white text-blue-600 dark:bg-gray-800 dark:text-blue-400 font-semibold py-3 px-6 rounded-lg shadow-md hover:bg-blue-50 dark:hover:bg-gray-700 transition duration-300 transform hover:scale-105 flex items-center"
            >
              <Zap className="h-5 w-5 mr-2" />
              Book a Call Now
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;